.w_slider{
  width: 85%;
  .swiper-slide{
    .swiper-clip{
      width: 100%;
      overflow: hidden;

      img{
        max-height: 600px;
        width: 100%;
        object-fit: cover;
      }

      .slider-detail{
        opacity: 0;
        width: 100%;
        position: absolute;
        bottom: 0;
        background-color: rgba(30, 30, 30, 0.8);
        color: $white;
        transform: translateY(100%);
        transition: all ease 0.2s;
      }

      &:hover{
        .slider-detail{
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }
}
