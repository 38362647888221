#newsletter-modal{
  @media (min-width: 576px){
    .modal-dialog {
      max-width: 800px;
      margin-right: auto;
      margin-left: auto;
    }
  }
  .modal-header{
    border: none;
  }
}
