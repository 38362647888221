.artist-links {
  column-count: 1;

  @include media-breakpoint-up(sm) {
    column-count: 2;
  }
  @include media-breakpoint-up(lg) {
    column-count: 4;
  }

  &-link {
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-bottom: 5px;
    font-weight: 500;
    color: $primary;
    position: relative;

    &:after {
      content: '';
      margin-top: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      color: transparent;
      background: $primary;
      height: 2px;
      transition: all ease 0.2s;
    }

    &:hover, &:active {
      color: $primary;

      &:after {
        width: 100%;
      }
    }
  }
}


.artist-block {
  a {
    color: $primary;
    text-decoration: none;
  }

  img {
    object-fit: cover;
  }

  .artist-hover {
    opacity: 0;
    transition: all ease 0.3s;
  }

  .artist-button {
    background-color: transparent;
    font-weight: 600;
    letter-spacing: 1px;

    svg {
      fill: $primary;
      transition: all ease 0.3s;
    }
  }

  //&:hover {
  //  a {
  //    color: $white;
  //  }
  //
  //  .artist-hover {
  //    opacity: 1;
  //  }
  //
  //  .artist-button {
  //    background-color: $primary;
  //
  //    svg {
  //      fill: $white;
  //      transform: translateX(10px);
  //    }
  //  }
  //}
}

//detail
.artist-detail {
  img {
    width: 190px;
    height: 190px;
  }
}

