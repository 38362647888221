textarea.form-control {
  min-height: 10rem;
}

.contact-form{

  width: 540px;
  @include media-breakpoint-down(md) {
    width: 100%;
  }

  .form-input{
    label{
      font-size: 16px;
      letter-spacing: 2.5px;
      color: #8B8B8B;
    }
    input, textarea, select{
      border: 0;
      border-bottom: 1px solid black;
      border-radius: 0;

      &:focus{
        outline: none;
      }
    }
    .btn{
      background: none;
      border: 0;
      border-bottom: 1px solid black;
      border-radius: 0;
    }

  }

  .contact-form-check{
    text-align: left;
    input[type=checkbox]{
      order: 1;
      border-radius: 0;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      margin-top: -2px;
    }
    label{
      order: 2;
      letter-spacing: 1px;
    }

    a{
      font-weight: 500;
      color: black;
    }
  }
}

.was-validated {

  .form-check-input,
  .form-select,
  .form-control {
    &:valid,
    &.valid {
      border-color: $input-border-color;
      box-shadow: none;
      &:focus {
        border-color: $input-border-color;
        box-shadow: none;
      }
    }
    &:invalid,
    &.is-invalid {
      border-color: $form-feedback-invalid-color;
      box-shadow: none;
      &:focus {
        border-color: $form-feedback-invalid-color;
        box-shadow: none;
      }
    }
  }
}

.password-input{
  border-bottom: 1px solid black;

  label{
    font-size: 16px;
    letter-spacing: 2.5px;
    color: #8B8B8B;
  }

  input, textarea, select{
    border: 0;
    border-radius: 0;
    &:focus{
      outline: none;
    }
  }
  .btn{
    background: none;
    border: 0;
    border-bottom: 1px solid black;
    border-radius: 0;
  }

  input[type="password"] ~ .toggle-password .icon--hide {
    display: none;
  }

  input[type="text"] ~ .toggle-password .icon--show {
    display: none;
  }

  .toggle-password{
    all: unset;
    position: absolute;
    top: 50%;
    right: 0.9375rem;
    transform: translateY(-50%);
    // cursor: pointer;

    svg{
      transition: all ease 0.3s;
    }

    &:hover svg, &:focus svg{
      fill: $black;
    }
  }
  .pass-success, .pass-danger{
    position: absolute;
    top: 50%;
    right: 3rem;
    transform: translateY(-50%);
  }
}
