main#page-home {

  .l-widget{
    min-height: 850px;
    max-height: 100%;
  }

  #map {
    @include media-breakpoint-down(lg) {
      min-height: 75vh;
    }

    &::after {
      @include media-breakpoint-up(lg) {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 33%;
        height: 100%;
        z-index: 1;
        background: linear-gradient(90deg, rgba($light, 0) 0%, rgba($light, 1) 100%);
        pointer-events: none;
      }
    }
  }

  .banner-text{
    width: 100%;

    svg{
      fill: white;
    }

    .body-button{
      border: 1px solid $white;
      background-color: transparent;
      color: $white;

      svg{
        fill: $white;
        transition: all ease 0.3s;
      }

      &:hover{
        background-color: $white;

        a{
          color: $primary;
        }

        svg{
          transform: translateX(10px);
          fill: $primary;
        }
      }
    }

  }

  .banner-wrapper{
    position: relative;
    height: 100%;

    &:before{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.9);
      opacity: 0.5;
      z-index: 2;
    }
  }
  .swiper{
    width: 85%;
  }

  .newsletter{
    width: 700px;

    .cms-text{
      width: 320px;
    }
  }
}
