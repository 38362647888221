.l-site-footer {

  $social-icons-size: 18px;
  $link-opacity: 1;

  .footer-top, .footer-bottom {
    border-top: 1px solid;
  }

  .footer-top {
    border-color: $secondary;
  }

  .footer-bottom {
    border-color: $light;
  }

  .footer-img {
    width: 8.125rem;
    padding-top: 1rem;
    @include media-breakpoint-down(sm) {
      margin-bottom: 1rem;
    }
  }

  &,
  .nav-item > a {
    @include font-size(14px);
    text-decoration: none;
    color: $primary;
    position: relative;
    padding-bottom: 10px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 0%;
      color: transparent;
      background: $primary;
      height: 2px;
      transition: all ease 0.2s;
    }
  }

  .nav-item > a {
    text-transform: uppercase;
    @include font-size(12px);
    font-weight: 500;
    letter-spacing: 1px;
  }

  .nav-item > a:hover,
  .nav-item > a:focus,
  .nav-item > a:active, {
    color: $primary;

    &:after {
      width: 70%;
    }
  }

  .author-link:not(:hover):not(:focus):not(:active),
  .nav-social a:not(:hover):not(:focus):not(:active) {
    opacity: $link-opacity;
  }

  .nav-social {
    $padding-x: 0.375rem;
    $padding-y: 0.375rem;

    margin: 0 (-$padding-x);

    .nav-item {
      padding: $padding-y $padding-x;
    }

    .icon {
      width: $social-icons-size;
      height: $social-icons-size;
    }
  }

  .nav-legal .nav-item:not(:last-of-type)::after {
    $padding-x: 0.375rem;

    content: '/';
    display: inline-block;
    padding-left: $padding-x;
    padding-right: $padding-x;
  }

  .col-meta {
    padding-top: 0.25rem; // x-height fix
  }

  .footer__nav {
    display: flex;
    justify-content: space-between;

    //display: grid;
    //grid-template-columns: repeat(3, 1fr);
    //grid-template-rows: 1fr;
    //grid-column-gap: 0px;
    //grid-row-gap: 0px;
    //
    //&-left {
    //  grid-area: 1 / 1 / 2 / 2;
    //
    //  ul {
    //    gap: 1.2rem;
    //    @include media-breakpoint-down(xl) {
    //      gap: 0.2rem;
    //    }
    //  }
    //}
    //
    //&-center {
    //  grid-area: 1 / 2 / 2 / 3;
    //}
    //
    //&-right {
    //  grid-area: 1 / 3 / 2 / 4;
    //
    ul {
      gap: 1.2rem;
      @include media-breakpoint-down(xl) {
        gap: 0.2rem;
      }
    }
    //}
  }

  @include media-breakpoint-down(sm) {
    .footer__nav {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;

      &-left {
        grid-area: 2 / 1 / 3 / 2;
      }

      &-center {
        grid-area: 1 / 1 / 2 / 3;
      }

      &-right {
        grid-area: 2 / 2 / 3 / 3;
      }
    }
  }

  .copyright-link {
    color: $primary;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
