:root {
  --scale-width: 600px;
  --image-width: 0;

  --bench-width: 4000px;
  --md-scale: 0.7;
  --sm-scale: 0.5;
}



.view__room {
  background-color: rgb(246, 247, 245);
  .modal-dialog{
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .modal-content {
    background-color: rgb(246, 247, 245);
  }

  .bench {
    width: var(--bench-width);
  }

  &-img-box {
    margin-top: 3.75rem;
    min-height: 400px;
  }

  &-img {
    display: block;
    height: auto;
    width: var(--image-width);
    box-shadow: 10px 10px 26px -12px rgba(0, 0, 0, 0.26);
  }

  #scale {
    width: var(--scale-width);
    height: 1px;
    background: $primary;
    text-align: center;

    &:before, &:after {
      display: block;
      content: '';
      width: 1px;
      height: 10px;
      background: $primary;
    }
  }

  // responsive scaling
  @include media-breakpoint-down(md) {
    .view__room-img{
      width: calc(var(--image-width) * var(--md-scale)) ;
    }
    #scale{
      width: calc(var(--scale-width) * var(--md-scale)) ;
    }
    .bench{
      width: calc(var(--bench-width) * var(--md-scale)) ;
    }
  }

  @include media-breakpoint-down(sm) {
    .view__room-img{
      width: calc(var(--image-width) * var(--sm-scale)) ;
    }
    #scale{
      width: calc(var(--scale-width) * var(--sm-scale)) ;
    }
    .bench{
      width: calc(var(--bench-width) * var(--sm-scale)) ;
    }
  }


}



