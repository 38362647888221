@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

// colors
$primary: #1E1E1E;
$secondary: #8B8B8B;

$light: #F0F0F0;
$dark: #2E2E2E;

$background: #F8F8F8;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "background": $background,
);

// Custom
$container-fluid-max-width: 100rem;
$z-index-cookies: 10000;

// Options
$enable-negative-margins: true;

// Spacing
$spacer: 0.625rem;
$spacers: (
  0: 0,
  1: $spacer,
  2: $spacer * 2,
  3: $spacer * 3,
  4: $spacer * 4,
  5: $spacer * 5,
  6: $spacer * 6,
  8: $spacer * 8,
  10: $spacer * 10,
  12: $spacer * 12,
);

// Grid columns
$grid-gutter-width: 2rem;
$container-padding-x: $grid-gutter-width * 0.625;
$gutters: $spacers;

// Typography
$h1-font-size: $font-size-base * 3.6;
$h2-font-size: $font-size-base * 2.5;
$h3-font-size: $font-size-base * 2;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  10: 0.625rem,
  12: 0.75rem,
  14: 0.875rem,
  16: 1rem,
  18: 1.125rem,
);

$headings-margin-bottom: $spacer * 2;

// Navbar
$navbar-dark-color: $white;
$navbar-light-color: $black;
