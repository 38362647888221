main#newsletter-subscribe {
  @include media-breakpoint-up(xl) {
    background: linear-gradient(90deg, rgba($background, 0) 55%, rgba($background, 1) 45%);
  }

  .newsletter-section {
    min-height: 43.75rem;
    @include media-breakpoint-down(md) {
      height: 100%;
    }
  }

}
