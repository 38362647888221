.l-widget--image {
  img {
    width: 100%;
    height: 550px;
    object-fit: cover;
  }
  @include media-breakpoint-down(md){
    img{
      height: 300px;
    }
  }
}

