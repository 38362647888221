.artwork-block {
  .artwork {
    height: 32rem;
    //border: 1px solid $gray-300;
    background-color: $background;

    @include media-breakpoint-down(xl) {
      height: 24rem;
    }
    @include media-breakpoint-down(md) {
      height: 32rem;
    }
    @include media-breakpoint-down(sm) {

    }
  }

  .artwork-img {
    height: 100%;
    object-fit: contain;
    transition: all ease 0.2s;
  }

  .artwork-artist-tag {
    font-size: 15px;
    color: $gray-600;
  }

  .artwork-year {
    margin-top: 0.15rem;
    width: 50px;
  }

  &:hover {
    .artwork-img {
      transform: scale(1.1);
    }
  }
}

//detail
.artwork-detail {
  .artwork-img {
    max-height: 800px;
    max-width: 85%;
  }

  .artist-tag {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    width: 2rem;
    height: 2rem;

    &:after, &:before {
      @include font-size(24px);
    }
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-pagination {
    position: relative;

    .swiper-pagination-bullet {
      position: relative;
      width: 100%;
      border-radius: 0;
      background: none;
      padding-top: 1rem;
      padding-bottom: 1rem;

      &:after {
        display: block;
        content: '';
        background: var(--swiper-pagination-bullet-inactive-color, #000);
        width: 100%;
        height: 2px;
        border-radius: 0;
      }
    }
  }
}

.artwork-detail-info {
  border-top: 1px solid $secondary;

  a {
    color: $primary;
  }
}

//artist block
.artwork-detail-artist {
  background-color: $background;

  img {
    width: 190px;
    height: 190px;
  }
}


//favorites btn
.favorite-heart {
  background: none;
  border: none;
  padding: 0;

  &.active {
    .favorite {
      fill: $black;
    }
  }

  .favorite {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover {
      fill: $black;
    }
  }
}
