main#page-contact {
  #map {
    @include media-breakpoint-down(lg) {
      min-height: 75vh;
    }

    &::after {
      @include media-breakpoint-up(lg) {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 33%;
        height: 100%;
        z-index: 1;
        background: linear-gradient(90deg, rgba($light, 0) 0%, rgba($light, 1) 100%);
        pointer-events: none;
      }
    }
  }


  .contact-adress-line {
    max-width: 2rem;
  }

  .contact-info-links {
    li {
      a {
        text-decoration: none;
      }

      svg {
        transition: all ease 0.2s;
      }
    }

    li:hover {
      svg {
        transform: translateX(-5px);
      }
    }
  }

  .img-fluid {
    object-fit: cover;
  }

  //contact form
  .contact-form {
    width: 100%;

    .row {
      .col {
        padding: 0;
      }
    }
  }

  .contact-info {
    img{
      aspect-ratio: 4/3;
      object-fit: cover;
      width: 100%;
    }
  }
}
