.title_block {
  position: relative;
  width: 600px;

  h1, h2 {
    overflow: hidden;
    position: relative;
    margin: 100px auto;

    &:after {
      content: '';
      display: inline-block;
      height: 2px;
      width: 130px;
      background: $primary;
      right: 0;
      margin-bottom: 10px;
      margin-left: 30px;

      @media (max-width: 350px) {
        margin-left: 0px;
      }
    }
  }
}

.l-widget--quote {
  background-color: $background;
  cite::before {
    content: '';
    display: inline-block;
    height: 1px;
    width: 30px;
    background: $secondary;
    margin: 1rem 0;
  }

  .quote-center{
    text-align: center;
    cite{
      display: flex!important;
      flex-flow: column;
      align-items: center;
    }
  }
}
