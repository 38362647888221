main#page-login, main#page-password-forgotten, main#page-password-reset, main#page-register {
  border-top: 1px solid $secondary;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0) 50%, rgba(250,249,249,1) 50%);
  min-height: 800px;

  @include media-breakpoint-down(lg) {
    background: rgb(255,255,255);
  }

  .login-cta{
    background: rgba(250,249,249);

  }


  // contact form overwrite
  .contact-form {
    width: 100%;
  }
}
