.body-button {
  text-decoration: none;
  transition: all ease 0.3s;
  font-size: 16px;
  padding: 12px 30px 12px 30px;

  a {
    text-decoration: none;
  }
}

.button-line {
  border-bottom: 1px solid $primary;
  color: $primary;
  padding: 6px 0px 6px 0px;
  text-transform: capitalize;

  a {
    color: $primary;
  }

  svg {
    transition: all ease 0.3s;
  }

  &:hover {
    background-color: $white;
    color: $primary;

    svg {
      transform: translateX(10px);
    }
  }
}

.button-black {
  border: 1px solid $primary;
  background-color: $primary;
  color: $white;

  svg {
    fill: $white;
    transition: all ease 0.3s;
  }

  &:hover {
    background-color: $white;
    color: $primary;

    svg {
      transform: translateX(10px);
      fill: $primary;
    }
  }

}

.button-white {
  border: 1px solid $white;
  background-color: transparent;
  color: $white;

  svg {
    fill: $white;
    transition: all ease 0.3s;
  }

  &:hover {
    background-color: $white;
    color: $primary;

    svg {
      transform: translateX(10px);
      fill: $primary;
    }
  }

}

.button-outline {
  border: 1px solid $primary;
  background-color: transparent;
  color: $primary;

  a {
    color: $primary;
  }

  svg {
    fill: $primary;
    transition: all ease 0.3s;
  }

  &:hover {
    background-color: $primary;
    color: $white;

    a {
      color: $white;
    }

    svg {
      transform: translateX(10px);
      fill: $white;
    }
  }

  &.login-btn {
    svg:nth-child(1) {
      transform: none;
      margin-top: -2px;
      margin-right: 2px;
    }
  }

}

.button-return{
  position: relative;
  text-decoration: none;
  svg{
    position: relative;
    transition: all ease 0.3s;
    margin-top: -0.2rem;
  }
  &:hover{
    svg{
      margin-left: -1rem;
    }
  }

}

.share-button{
  all: unset;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
    color: $black;
  }
}
