.event-block{
  p{
    word-break: break-word;
    font-size: 16px;
    line-height: 30px;
  }
  a{
    color: inherit;

    svg{
      transition: all ease 0.2s;
    }

    &:hover{
      color: inherit;

      svg:last-child{
        transform: translateX(10px);
      }
    }
  }
}
.event-map{
  height: 340px;
  width: 100%;
  object-fit: cover;
}


.past__events, .future__events{
  img{
    transition: $transition-base;
  }

  &-overlay{
    transition: $transition-base;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($primary, 0.5);
    opacity: 0;
  }

  &-wrapper:hover{
    img{
      transform: scale(1.1);
    }
    .past__events-overlay, .future__events-overlay{
      opacity: 1;
    }
  }

  &-cta{
    cursor: pointer;
    transition: $transition-base;
    color: $secondary;
    &:hover{
      color: $primary;
    }
  }
}
