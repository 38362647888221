main#page-coming-soon{
  width: 100vw;
  height: 100vh;
}
.cs-header{
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid black;
  h1{
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 2.5px;
    margin: 0;
  }
}
.cs-container{
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;

    .cs-body{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      .cs-logo{
        padding-top: 30px;
        padding-bottom: 30px;
        margin-bottom: -30px;
      }
      .cs-qoute{
        padding-top: 30px;
        padding-bottom: 0px;
        font-size: 30px;
        font-weight: 400;
        letter-spacing: 2.5px;
        text-align: center;
      }
      .cs-form{
        padding-top: 0px;
        padding-bottom: 30px;
      }

      .cs-form-wrapper{
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        .cs-email{
          width: 400px;
        }
        .cs-input{
          display: flex;
          margin-bottom: 20px;
          input{
            border: 0;
            border-bottom: 1px solid black;
            border-radius: 0;
            font-size: 16px;
            letter-spacing: 2.5px;
            &:focus{
              outline: none;
            }
          }
          .btn{
            background: none;
            border: 0;
            border-bottom: 1px solid black;
            border-radius: 0;
          }

        }
      }
      .form-check{
        font-size: 13px;
        font-weight: normal;
        display: flex;
        align-items: center;

        .form-check-input[type=checkbox]{
          order: 1;
          border-radius: 0;
          width: 20px;
          height: 20px;
          margin-right: 10px;
          margin-top: -2px;
        }
        label{
          order: 2;
          letter-spacing: 1px;
        }

        a{
          font-weight: 500;
          color: black;
        }
      }
    }

  .cs-body, .cs-foot{
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: center;
  }


  .cs-foot{
    padding-top: 15px;
    padding-bottom: 15px;
    .cs-socials a{
      text-decoration: none!important;
      padding: 5px;
    }
    .cs-contact{
      font-size: 18px;
      letter-spacing: 2.5px;
      font-weight: 400;
      padding-top: 20px;

      a{
        color: black;
        font-weight: 500;
      }
    }
  }
}



@media only screen and (max-width: 768px) {

  .cs-container{
    .cs-header{
      h1{
        font-size: 16px;
      }
    }
      .cs-body{
        .cs-logo img{
          width: 180px;
        }
        .cs-qoute{
          padding-left: 10px;
          padding-right: 10px;
          font-size: 18px;
        }

        .cs-form-wrapper{
          .cs-email{
            width: 300px;
          }
        }
      }
      .cs-foot{
        .cs-contact{
          padding-top: 20px;
          font-size: 16px;
          letter-spacing: 1.5px;
        }
        .cs-socials img{
          width: 20px;
        }
      }
  }
}
