@each $name, $value in $theme-colors {
  .fill-#{$name} {
    fill: $value;
  }

  .stroke-#{$name} {
    stroke: $value;
    fill: none;
  }
}

.fill-white {
  fill: $white;
}

.fill-black {
  fill: $primary;
}
.fill-succes{
  fill: $success;
}
.fill-danger{
  fill: $danger;
}
.fill-secondary{
  fill: $secondary;
}

.stroke-white {
  stroke: $white;
  fill: none;
}

.stroke-black {
  stroke: $primary;
  fill: none;
}
.stroke-secondary{
  stroke: $secondary;
  fill: none;
}
