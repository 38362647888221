body{
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
}
.cms-text {
  word-break: break-word;
  color: $secondary;
  font-size: 16px;
  line-height: 30px;

  p a:not(.btn) {
    text-decoration: underline;
    color: $black;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  ol,
  ul {
    margin-top: $grid-gutter-width * 0.5;
    margin-bottom: $grid-gutter-width * 0.5;
  }
}

.body-link{
  color: $primary;
  transition: all ease 0.2s;

  &:hover{
    color: $secondary;
  }
}

.text-balance{
  text-wrap: balance;
}
