.l-site-nav {
  //background-color: $white;
  $nav-breakpoint: 'lg';
  $offcanvas-width: 18.75rem;
  $offcanvas-top: 0; // could also be navbar height
  $offcanvas-bg-color: $white;
  $offcanvas-y: 2.5rem;
  $offcanvas-nav-link-padding-x: 0.625rem;
  $offcanvas-nav-link-padding-y: 1.625rem;
  $offcanvas-active-bg: none;

  position: relative;
  z-index: $zindex-offcanvas;

  .navbar-brand img {
    width: 8rem;
    padding-bottom: 1rem;
  }

  .nav-item{
    a {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 0%;
        color: transparent;
        background: $primary;
        height: 1.5px;
        transition: all ease 0.2s;
      }
    }

    &:hover a, &:active a {
      color: $primary;

      &:after {
        width: 50%;
      }
    }
  }


  //.right-nav {
  //  li:last-child {
  //    border: 1px solid $primary;
  //    padding: 6px 30px 6px 30px;
  //    transition: all ease 0.2s;
  //
  //    a {
  //      padding: 0;
  //
  //      &:after {
  //        display: none;
  //      }
  //    }
  //
  //    &:hover {
  //      background-color: $primary;
  //
  //      a {
  //        color: $white !important;
  //      }
  //    }
  //  }
  //}

  .offcanvas {
    border-left: 0;

    @include media-breakpoint-up($nav-breakpoint) {
      position: static;
      visibility: visible !important;
      background: none;
      border: 0;
      transform: none;
      width: auto;
      margin-left: auto;
    }

    @include media-breakpoint-down($nav-breakpoint) {
      width: $offcanvas-width;
      background-color: $offcanvas-bg-color;
      padding-top: $offcanvas-y;
      padding-bottom: $offcanvas-y;
    }
  }

  .offcanvas .btn-close {
    @include media-breakpoint-up($nav-breakpoint) {
      display: none;
    }
  }

  .offcanvas .nav-link {
    text-transform: uppercase;
    @include font-size(14px);
    letter-spacing: 1px;
    white-space: nowrap;
    padding-left: 1rem;
    padding-right: 1rem;
    @include media-breakpoint-down($nav-breakpoint) {
      padding: $offcanvas-nav-link-padding-x $offcanvas-nav-link-padding-y;

      &.active {
        background: $offcanvas-active-bg;

        &:after {
          width: 50%;
        }
      }
    }
  }

  .navbar-languages {
    &::before {
      @include media-breakpoint-up($nav-breakpoint) {
        content: '';
        margin-left: $nav-link-padding-x;
        margin-right: $nav-link-padding-x;
        border-left: 1px solid $black;
        opacity: 0.5;
        height: 14px;
      }
    }

    .dropdown-menu {
      min-width: 0;
      left: -17px; // text alignment

      @include media-breakpoint-up(lg) {
        left: -9px; // text alignment
      }
    }
  }

  &:not(.navbar-dark) {
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background: $dark;
    }
  }

  &.navbar-dark {
    .navbar-languages::before {
      border-color: $white;
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background: $white;
    }
  }
}
